<template>
  <div class="manage-account animatedBox">
    <div class="container fluid">
      <div class="card p-3">
        <fd-form @submit.prevent="submitForm" @error="validateFail">
          <h4 class="mainTitle mb-4">Change Account Password</h4>
          <div class="main-contents">
            <fd-input
              class="mb-2"
              type="password"
              :label="`New Password`"
              v-model="account.password"
              :validators="[validator.required]"
            />
            <fd-input
              class="mb-2"
              type="password"
              :label="`Retype Password`"
              v-model="account.retypePassword"
              :validators="[validator.required]"
            />
          </div>
          <div class="mt-4 text-right">
            <button type="submit" class="btn main">Update</button>
          </div>
        </fd-form>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";
import { AccountModel } from "@/models";

export default {
  name: "manage-account",
  data() {
    return {
      account: {
        password: "",
        retypePassword: ""
      },

      validator: {
        required: required
      }
    };
  },
  methods: {
    validateFail() {
      this.$notify({
        group: "alert",
        type: "error",
        title: "Error",
        text: "Validation failed, please check if all the inputs are valid."
      });
    },
    submitForm() {
      if (this.account.password != this.account.retypePassword) {
        alert("The two password are not the same.");
        return;
      }

      this.updatePassword();
    },
    async updatePassword() {
      this.$store.commit("setIsLoading", true, { root: true });
      try {
        const request = await this.$store.dispatch(
          "manageAccount/updatePassword",
          AccountModel.changePasswordPayload({
            password: this.account.password
          })
        );

        this.$store.commit("setIsLoading", false, { root: true });
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Password has been updated successfully."
        });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.manage-account {
  form {
    @extend %formDesign;
  }
}
</style>